import React from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react"
import axios from 'axios'
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';

import {
  Button,
  Cascader,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  TreeSelect,
  Checkbox,
  Modal,
} from 'antd';

const { TextArea } = Input;


export default function AdminUser() { 
  const { id_telegram } = useParams()
  const [ userData, setUserData ] = useState([])
  const [ items, setItems ] = useState([])
  const [alert, setAlert] = useState('')
  const navigate = useNavigate()

  const TOKEN = '6946615646:AAFmrrcerDAGq4vd62OY6FQqbvbBnXpl5us';
  const chatIds = ['763269687', '498982098', '1147960226', '6907261500']; // Список ID чатов


  const sendAlert = () => {
    const sendNotification = async () => {
        for (const chatId of chatIds) {
          try {
            const response = await axios.get(`https://api.telegram.org/bot${TOKEN}/sendMessage`, {
              params: {
                chat_id: chatId,
                text: alert,
              },
            });
            console.log(`Сообщение отправлено в чат ${chatId}:`, response.data);
          } catch (error) {
            console.error(`Ошибка при отправке сообщения в чат ${chatId}:`, error);
          }
        }
      };
      
      sendNotification();
  }

 
    return (
      <>
      <div className='adminFormUser'>
      <Form
      labelCol={{
        span: 5,
      }}
      wrapperCol={{
        span: 10,
      }}
      layout="horizontal"
      style={{
        maxWidth: 600,
      }}
    >
        <span>Введите текст для уведомления</span>
      <Form.Item label="">
        <TextArea rows={4} style={{minWidth: '300px'}} onChange={(e) => setAlert(e.target.value)}  value={alert} />

      </Form.Item>

      <Button type="primary" onClick={() => sendAlert()}>Отправить</Button>

    </Form>
    <div className='adminTabs'>

    
    </div>

    </div>
    
    </>
    )
}