const language = {
    en: {
        register_pay_text: 'Please pay 30 USDT per wallet to enter the "Smart Club":',
        register_form_title: 'Enter your details',
        rules_title: 'Club Rules', 
        user_greement: 'User Agreement',
        submit: 'Submit',
        text_greement: `Maintain the Club's operability except in cases where it is impossible due to reasons beyond the Administration's control
Provide comprehensive protection of the User's account
Protect information whose dissemination is restricted or prohibited by law by issuing warnings or deleting the account of the user who violated the rules
3. Responsibility of the Parties
The User is fully responsible for their decisions regarding participation in the club and the amount of investment in the Club
The User is responsible for complying with the Club Rules, Participation Conditions, and Chat Rules
The User is responsible for maintaining the hierarchy in the club and adhering to role rules
The User is personally responsible for timely payment of rewards to the trader
The User is personally responsible for adhering to the deposit placement deadlines
The User is personally responsible for updating the API key
The User is responsible for maintaining their personal Investor Journal and the information provided therein
The Club Administration is not responsible for the accuracy of information copied from other sources
The Administration is not responsible for any discrepancy between the services expected by the User and those actually received
The Administration is not responsible for services provided by third parties
In the event of force majeure (military actions, state of emergency, natural disaster, etc.), the Administration does not guarantee the preservation of information posted by the User or the uninterrupted operation of the information resource
4. Terms of the Agreement
4.1. The Agreement:

This Agreement comes into effect upon registration in SMART.club.

4.2. The Agreement ceases to be effective upon the release of a new version.

4.3. The Club Administration reserves the right to unilaterally amend this Agreement at its discretion.

The Administration notifies Users of changes to the Agreement in the Club chat via a general announcement.
        `,
        rules_img: './img/cs.jpg',
        trade_title: 'Trading Rewards',
        all: "All",
        lvl: 'lvl', 
        ref_false: 'There are no referrals at this level',
        slider_sabmit: 'Open', 
        slider_slide_1: 'Smart Club chat', 
        slider_slide_2: 'Investor', 
        slider_slide_3: 'There are questions', 
        slider_slide_4: 'Presentation of Smart Club', 
        slider_slide_5: 'Referral', 
        slider_title_manager_1: 'Trade questions — ',
        slider_title_manager_2: 'API help — ',
        slider_title_manager_3: 'Manager — ',
        slider_title:'Managers',
        qery_title: 'Fill out the application',
        qery_subscribe: 'Your application has been created',
        qery_accepted: 'Your application has been accepted', 
        qery_processed  :'The application is being processed by the manager',
        qery_now: 'Apply now',
        min_sum: 'Minimum withdrawal amount',
        max_sum: 'Maximum withdrawal amount',
        sum: 'Sum',
        cancel: 'Cancel',
        sum_api: 'Sum in API',
        key_api: "API key",
        referal_img: './img/ReferralEN.jpg', 
        investor_img: './img/InvestEN.jpg',
        reward_text: 'Until completion Reward ',
        day: 'Days',
        reward_confirm: 'Target date reached!',
        button: './media/svg.svg',


    },
    ru: {
        register_pay_text: 'Пожалуйста, произведите оплату входа в "Smart Club" в размере 30 USDT на кошелёк:',
        register_form_title: 'Введите ваши данные',
        rules_title: 'Правила клуба',
        user_greement: 'Пользовательское соглашение',
        submit: 'Отправить',
        text_greement: `Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между SMART.club (далее Администрация или Клуб) с одной стороны и пользователем/ участником SMART.club с другой.
                            <br />
                            <br />
                            SMART.club является добровольным обьединением граждан, закрытым инвестиционным клубом, и не является ни юридическим лицом, ни коммерческой организацией, ни общественным объединением, ни средством массовой информации, ни организацией работающей по принципу доверительное управление.
                            <br />
                            <br />
                            Цель клуба - обмен мнениями и идеями в области криптовалюты. Все остальные действия на рынке, члены Клуба осуществляют самостоятельно, под свою ответственность, на собственные средства и на собственных счетах.
                            <br />
                            <br />
                            Вступая в SMART.club, Вы соглашаетесь с условиями данного соглашения.
                            Если Вы не согласны с условиями данного соглашения, не используйте и не вступайте в  SMART.club!
                            <br />
                            <br />
                            1. Предмет соглашения
                            <br />
                            <br />
                            Администрация клуба предоставляет пользователю право на вступление в SMART.club и быть полноценным участником клуба, с правом использованиях информации и услуг клуба.
                            <br />
                            <br />
                            2. Права и обязанности сторон
                            <br />
                            <br />
                            2.1. Пользователь имеет право:
                            - осуществить регистрацию в SMART.club
                            - получать информацию о жизни и событиях в SMART.club
                            - комментировать контент, согласно Правилам участи в чатах
                            - копировать информацию с разрешения Администрации клуба
                            - использовать информацию клуба в личных некоммерческих целях
                            - использовать информацию клуба в коммерческих целях с разрешения Администрации
                            - осуществить инвестиции в SMART-CLUB
                            <br />
                            <br />
                            2.2. Администрация имеет право:
                            - по своему усмотрению и необходимости создавать, изменять, отменять правила
                            - ограничивать доступ к любой информации в клубе
                            - создавать, изменять, удалять информацию
                            - отказывать в регистрации без объяснения причин
                            <br />
                            <br />
                            2.3. Пользователь обязуется:
                            - обеспечить достоверность предоставляемой информации
                            - обеспечивать сохранность личных данных от доступа третьих лиц
                            - обновлять персональные данные, предоставленные при регистрации, в случае их изменения
                            - не распространять информацию, которая направлена на пропаганду войны, разжигание национальной, расовой или религиозной ненависти и вражды, а также иной информации, за распространение которой предусмотрена уголовная или административная ответственность
                            - не нарушать работоспособность Клуба
                            - не создавать несколько учётных записей в SMART.club, если фактически они принадлежат одному и тому же лицу
                            - не совершать действия, направленные на введение других Пользователей в заблуждение
                            - не передавать в пользование свою учетную запись и/или логин и пароль своей учетной записи третьим лицам
                            - не размещать материалы рекламного, эротического, порнографического или оскорбительного характера, а также иную информацию, размещение которой запрещено или противоречит нормам действующего законодательства
                            - не использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с Клубом, его сервисами, приложениями, сайтом, чатов и Клуба в целом.
                            <br />
                            <br />
                            2.4. Администрация обязуется:
                            - поддерживать работоспособность Клуба за исключением случаев, когда это невозможно по независящим от Администрации причинам.
                            - осуществлять разностороннюю защиту учетной записи Пользователя
                            - защищать информацию, распространение которой ограничено или запрещено законами путем вынесения предупреждения либо удалением учетной записи пользователя, нарушившего правила
                            <br />
                            <br />
                            3. Ответственность сторон
                            <br />
                            <br />
                            - Пользователь лично несет полную ответственность за свои решения об участии в клубе и об объеме инвестиции в Клуб
                            - ⁠Пользователь несет ответственность за соблюдение Правил клуба, Условий участия в клубе и Правил чатов
                            - ⁠Пользователь несет ответственность за соблюдение иерархии в клубе и соблюдения правил роли
                            - ⁠Пользователь несет личную ответственность за сроки выплат  вознаграждений трейдеру
                            - ⁠Пользователь несет личную ответственность за соблюдение сроков размещения депозита
                            - ⁠Пользователь несет личную ответственность за обновление API- ключа
                            - ⁠Пользователь несет ответственность за ведение личного Журнала Инвестора и предоставленную в ней информацию
                            - Администрация клуба не несет никакой ответственности за достоверность информации, скопированной из других источников
                            - Администрация не несёт ответственность за несовпадение ожидаемых Пользователем и реально полученных услуг
                            - Администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами
                            - в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение, стихийное бедствие и т. д.) Администрация не гарантирует сохранность информации, размещённой Пользователем, а также бесперебойную работу информационного ресурса
                            <br />
                            <br />
                            4. Условия действия
                            <br />
                            <br />
                            4.1. Соглашения:
                            <br />
                            <br />
                            Данное Соглашение вступает в силу при регистрации в SMART.club.
                            4.2. Соглашение перестает действовать при появлении его новой версии.
                            <br />
                            <br />
                            4.3. Администрация клуба оставляет за собой право в одностороннем порядке изменять данное соглашение по своему усмотрению.
                            <br />
                            <br />
                            Об  изменениях в Соглашении, Администрация оповещает  Пользователей  в чате Клуба в виде общего объявления.`,
        rules_img: './img/cs.jpg',
        trade_title: 'Торговые вознаграждения',
        all: 'Все',
        lvl: 'ур',
        ref_false: 'Рефералов данного уровня нет',
        slider_sabmit: 'Открыть', 
        slider_slide_1: 'Smart Club чат', 
        slider_slide_2: 'Инвестор', 
        slider_slide_3: 'Появились вопросы', 
        slider_slide_4: 'Презентация Smart Club', 
        slider_slide_5: 'Реферал', 
        slider_title_manager_1: 'Вопросы по торговым — ',
        slider_title_manager_2: 'Помощь по АПИ — ',
        slider_title_manager_3: 'Менеджер Smart Club  —',
        slider_title:'Менеджеры',
        qery_title: 'Заполните заявку',
        qery_subscribe: 'Ваша заявка создана',
        qery_accepted: 'Ваша заявка принята', 
        qery_processed  :'Заявка обрабатывается менеджером',
        qery_now: 'Подать заявку',
        min_sum: 'Минимальная сумма вывода',
        max_sum: 'Максимальная сумма вывода',
        sum: 'Сумма', 
        cancel: 'Отклонить',
        sum_api: 'Сумма в API',
        key_api: "Ключ в API",
        referal_img: './img/referal.jpg', 
        investor_img: './img/investor.jpg',
        reward_text: 'До завершения Reward ',
        day: 'Дней',
        reward_confirm: 'Целевая дата достигнута!',
        button: './media/Button.svg',

    }
}


export default language